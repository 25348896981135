.Header {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #D5E3E3;
    font-size: 20px;
    font-weight: bold;
    color: #bcb8d3;
}

.Logo {
    flex: 1;
}