.Layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Content {
    background-color: #EEE;
    background-image: url('../../assets/images/bg-mobile.jpg');
    background-size: cover;
    flex: 1;
}

@media (orientation: landscape) {
    .Content {
        background-image: url('../../assets/images/bg.jpg');
        background-position: center;
    }
}



@media (min-width: 1000px) and (min-height: 700px) {
    .Content {
        background-image: url('../../assets/images/bg.jpg');
        background-position: center;
    }
}